.products-list {
  .product-item {
    width: 100%;
    margin-bottom: $product-items-spacing-y;

    &:last-child {
      margin-bottom: 0;
    }

    .product-item-inner,
    [class^='swatch-opt-'] {
      position: relative;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .product-item-info {
    @extend %u-flex-row-xcenter-ycenter;
    align-items: flex-start;
    background: white;
    padding: $product-item-padding-mobile;

    @include respond-to-up(xl) {
      padding: $product-item-padding-desktop * 2;
    }

    @include respond-to-down(smm) {
      flex-direction: column;
      align-items: center;
    }

    .product-item-details {
      flex: 1 1 auto;
      padding-left: spacers(3);

      @include respond-to-up(md) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 100%;
      }

      @include respond-to-down(smm) {
        padding: 0;
        width: 100%;
      }
    }

    .product-item-inner {
      @extend %u-flex-col-xcenter-ycenter;
      padding-top: $product-item-photo-spacing-y;
      padding-left: 0;
      padding-right: 0;
      margin-top: auto;
    }
  }

  .product-item-photo {
    width: 100%;
    @include respond-to-up(smm) {
      width: 200px;
      min-width: 200px;
    }
    @include respond-to-up(md) {
      width: 270px;
      min-width: 270px;
    }
  }

  .product-item-description {
    width: 100%;
    order: 1;

    .action.more {
      display: inline-block;
      padding: spacers(2);
      color: $text-muted;
      font-size: $font-size-sm;

      @include respond-to-down(md) {
        font-size: $font-size-xs;
      }
    }
  }

  .product-item-actions {
    width: 200px;
    margin-right: auto;
    order: 2;

    @include respond-to-down(smm) {
      margin-left: 0;
      width: 100%;
    }

    .action.tocart {
      @include respond-to-down(smm) {
        max-width: none;
      }
    }
  }

  .price-box {
    margin-bottom: $product-item-photo-spacing-y;
  }
}
