//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/Magento_Catalog/web/css/module/page-title";
@import "magesparrow-blank/Magento_Catalog/web/css/module/layout";
@import "magesparrow-blank/Magento_Catalog/web/css/module/toolbar";
@import "magesparrow-blank/Magento_Catalog/web/css/module/modes";
@import "magesparrow-blank/Magento_Catalog/web/css/module/sorter";
@import "magesparrow-blank/Magento_Catalog/web/css/module/pages";
@import "magesparrow-blank/Magento_Catalog/web/css/module/compare-products";
@import "magesparrow-blank/Magento_Catalog/web/css/module/filter/filter-block";
@import "magesparrow-blank/Magento_Catalog/web/css/module/filter/price-slider";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/products-grid";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/products-list";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-items";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-item";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-item-inner";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-reviews-summary";
// @import "magesparrow-blank/Magento_Catalog/web/css/module/products/swatch-option";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-view";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-stock-sku";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-sku";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-stock";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-options-wrapper";
@import "magesparrow-blank/Magento_Catalog/web/css/module/fotorama";
// @import "magesparrow-blank/Magento_Catalog/web/css/module/products/tabs";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-review";
// @import "magesparrow-blank/Magento_Catalog/web/css/module/products/related-upsell-widgets";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/product-short-description";
@import "magesparrow-blank/Magento_Catalog/web/css/module/products/overview-readmore-link";
//------------------------------------//

@import "module/products/swatch-option-modified";
@import "module/products/tabs-modified";
@import "module/products/related-upsell-widgets-modified";

/* override max-width for PDP page */
body.catalog-product-view .product-info-wrapper,
.catalog-product-view .product.info.detailed>.product.data {
    max-width: $max-width;
}
body.catalog-product-view .page-main,
body.page-products .page-main {
    @include contained-max-width();
}
/* override the counter bg colour */
body .product[role=tablist]:not(.options) .switch .counter {
    background: map-get($theme-colors, 'dark');
}
/* fix colour of text within counter when tab is active */
body.am-tabs-view .product.data.items>.item.title.active>.switch .counter,
body.am-tabs-view .product.data.items>.item.title:not(.disabled)>.switch:active .counter {
    color: $white;
}

/* fix button widths being zero'd in related products carousel on product pages */
body .products-grid .product-item .action.tocart,
body .products-list .product-item .action.tocart {
    padding-left: 36px;
    padding-right: 36px;
}

/* fix overflow issue for details inside details tab */
.product.info.detailed .data.item.content .am-custom-tab {
    overflow-x: auto;
}

/* unset the positioning of the add to cart button on PDP xtento hide price */
body.catalog-product-view .box-tocart.xtento-hideprice,
body.catalog-product-view .product-options-bottom .box-tocart.xtento-hideprice {
    width: 100%;

    .actions {
        position: relative;
    }
}

body .xtento-hideprice ~ .product-info-price {
    display: none;
}

/* product page style amends */
body.catalog-product-view .product.media {
    width: 100%;

    @include respond-to-up($tweakpoint-nav-toggle) {
        width: 100%;
        max-width: calc(100% - 580px - 2rem);
        padding-right: 2rem;
    }
}

body.catalog-product-view .product-info-main {
    width: 100%;

    @include respond-to-up($tweakpoint-nav-toggle) {
        max-width: 580px;
        width: 100%;
        padding-left: 0;
    }

    .attribute.overview ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1rem;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        margin-top: 0;
        margin-bottom: 2.5rem;
    }

    .attribute.overview p {
        &:empty {
            display: none;
        }
    }

    [data-content-type="column"] {
        padding: 0;
    }
}

/* remove social links line */
.catalog-product-view .product-social-links {
    text-align: left;
}
.catalog-product-view .product-social-links .action.mailto:after,
.catalog-product-view .product-social-links .action.tocompare:after,
.catalog-product-view .product-social-links .action.towishlist:after {
    display: none;
}

.catalog-product-view .product-social-links .action.towishlist {
    margin-top: 10px;
    margin-bottom: 20px;

    &:before {
        line-height: 52px;
        background: #F2F2F2;
        color: #97999C;
        width: 52px;
        height: 52px;
        border-radius: 1000px;
        top: auto;
        font-size: 20px;
        vertical-align: middle;
    }

    > span {
        font-weight: 400;
        font-size: 16px;
        color: map-get($theme-colors, 'dark');
    }
}

.catalog-product-view .product-add-form .product-options-wrapper {
    border: 0;
    padding: 0;
}

.catalog-product-view .box-tocart .field.qty {
    max-width: none;
}

/* override and reset amasty tabs styles which override PDP tabs */
body.catalog-product-view {
    &.am-tabs-view .product.data.items>.item.title>.switch {
        font-size: 18px;
        margin: 0;

        @include respond-to-up($tweakpoint-nav-toggle) {
            font-size: 25px;
        }

        &:after {
            top: 10px;
        }
    }
}

body .product-item .product-image-wrapper {
    // height: unset;

    &:before,
    &:after {
        display: none;
    }

    .product-image-photo {
        //display: unset;
        //max-height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include respond-to-up(smm){
            //max-height: 127px;
        }
    }
}

/* product view background colour */
.catalog-product-view .product.info.detailed {
    background: #F2F2F2;
}

/* category page pagination show dropdown padding fix */
body .limiter .limiter-options {
    padding-left: 1rem;
    padding-right: 2rem;
}

/* override add to basket and contact us font sizes on listing pages */
body.page-products {
    .action.tocart.primary,
    .action.primary.xtento-hideprice-add_to_cart-button {
        font-size: 17px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

/* sidebar position sticky top value */
.catalog-category-view.page-layout-2columns-left .sidebar-container,
.catalog-category-view.page-layout-2columns-right .sidebar-container,
.catalog-category-view.page-layout-3columns .sidebar-container,
.catalogsearch-result-index.page-layout-2columns-left .sidebar-container,
.catalogsearch-result-index.page-layout-2columns-right .sidebar-container,
.catalogsearch-result-index.page-layout-3columns .sidebar-container {
    @include respond-to-up($tweakpoint-nav-toggle) {
        top: 210px;
    }
}

/* override the product name links */
body .product-item-link {
    color: $black;
    text-transform: uppercase;
    font-weight: 700;
}

/* override inner padding for products */
body .product-item>.product-item-info .product-item-details {
    padding: 0;
}

/* remove unnneccesary bottom margin from button when logged out */
body .products-grid .product-item .action.tocart.cti-login {
    margin-bottom: 0;
}

/* remove set height from select dropdown on locator pages */
body .amlocator-search-container .amlocator-select {
    height: auto;
}

/* block related center title */
body .block.related > .block-title,
body .block.upsell > .block-title,
body .block.crosssell > .block-title {
    text-align: left;
}

/* style buttons in case of wishlist icons present or not present */
body .product-item-actions .action.towishlist {
    margin-top: 1rem;
    display: block;
    text-align: left;
}

body .product-item-actions .action.tocart.primary {
    margin-bottom: 0;
}

/* width 100% needed for the customizable options select dropdown field */
body.catalog-product-view .product-add-form .product-options-wrapper {
    width: 100%;
    margin-bottom: 0;
}

/* remove wishlist icon from related, upsell and crosssells product items and make sure the titles are not uppercase */
body .block.related,
body .block.upsell,
body .block.crosssell {
    .block-title.title {
        text-transform: none;
        color: map-get($theme-colors, 'dark');
        letter-spacing: -0.02em;
        font-size: 22px;

        @include respond-to-up($tweakpoint-nav-toggle) {
            font-size: 28px;
        }
    }
    .action.towishlist {
        display: none;
    }
}

/* align the contact on product pages to the right */
body.catalog-product-view .product-table-container[role=table] .flex-row.center {
    text-align: right;
}

/* readmore expander */
.overview-readmore-link {
    margin-top: 1rem;
    margin-left: 0;
}

.product.attribute.overview .value {
    height: 70px;
    overflow: hidden;
    transition: height 0.6s ease-in-out;
    position: relative;

    a {
        display: inline-block;
    }

    &:before {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        top:0;
        background:linear-gradient(transparent, white);
    }
}

.product.attribute.overview.expanded .value {
    height: auto;
    overflow: unset;
    transition: height 0.6s ease-in-out;

    &:before {
        display: none;
    }
}

