.products-grid {
  margin-left: -1 * $product-item-spacing-x;
  margin-right: -1 * $product-item-spacing-x;
}

.product-item {
    position: relative;
    z-index: 1;

    .product-image-wrapper {
        position: relative;
        height: 0;
        display: block;
        width: 100%;
    }

    .product-image-position {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-item-photo {
        display: block;
        text-align: center;
        background: $product-item-photo-bg;
        overflow: hidden;
    }

    .product-image-photo {
        max-width: 100%;
        display: block;
    }

    .product-item-name {
        display: block;
        font-family: $product-item-title-font-family;
        font-size: $product-item-title-font-size;
        letter-spacing: $product-item-title-letter-spacing;
        line-height: $product-item-title-line-height;
        font-weight: $product-item-title-font-weight;
        margin-bottom: $product-item-title-spacing-y;
        word-wrap: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    @if $product-item-title-animated-underline == true {
        .product-item-link {
            @include animated-underline();
        }
    }

    .product-item-actions {
        margin-top: $product-item-photo-spacing-y;

        .actions-primary {
            .stock.unavailable {
                display: none;
            }
        }
        .actions-secondary {
            @extend %u-flex-row-xcenter-ycenter;
            justify-content: space-between;

            .action {
                padding: 0 $product-item-action-spacing;
            }
        }
    }

    .action.towishlist {
        @include icon-button('heart');
    }

    .action.tocompare {
        @include icon-button('compare');
    }

    .action.tocart {
        width: 100%;
        box-sizing: border-box;
        font-size: $font-size-sm;
        margin-bottom: $product-item-action-spacing-y;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        @include respond-to-down(smm) {
            padding-left: 0;
            padding-right: 0;
            padding-top: spacers(2);
            padding-bottom: spacers(2);
            font-size: $font-size-xs;
        }
        @if $product-item-add-to-cart-icon != false {
            span {
                @include icon-button(
                        $product-item-add-to-cart-icon,
                        $product-item-add-to-cart-icon-size
                );

                &:before {
                    margin-right: $product-item-action-spacing;

                    @include respond-to-down(smm) {
                        display: none;
                    }
                }
            }
        }
    }
}
