@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.modes-mode span, .action.sorter-action span, .pages .pages-label, .pages-items .label, .pages-items > .item > a.action span,
.pages-items > .item > .page.action span, .filter-subtitle, .filter-current .filter-label, .products-grid .product-reviews-summary .action.view, .review-form .choice .label, .review-control-vote label > span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.toolbar-products__control, .toolbar-products__pager, .toolbar-products .toolbar-sorter, .modes, .pages-items, .pages-items > .item > a.action,
.pages-items > .item > .page.action, .limiter, .filter-current .item, .filter-current .filter-value, .smile-es-range-slider, .products-grid .product-reviews-summary, .products-list .product-item-info, .product-items:not(.slick-slider), .product-item .product-item-actions .actions-secondary, .product-info-stock-sku, .product.sku, .product.data.items[role="tablist"] .switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.products-list .product-item-info .product-item-inner, .review-list .review-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.catalog-product-view .box-tocart .action.primary.tocart, .review-form-actions .action.primary {
  background: #b0aa00;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  padding: 26px 54px;
}

.catalog-product-view .box-tocart .action.primary.tocart:focus, .review-form-actions .action.primary:focus, .catalog-product-view .box-tocart .action.primary.tocart:hover, .review-form-actions .action.primary:hover {
  color: #fff;
  background: #fff831;
}

.smile-es-range-slider .action.small, .product-reviews-summary .action.add {
  background: transparent;
  color: #000;
  text-transform: none;
  font-weight: 800;
  padding: 0 5px;
  letter-spacing: -0.02em;
  position: relative;
  display: inline-block;
}

.smile-es-range-slider .action.small:after, .product-reviews-summary .action.add:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 70%;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.smile-es-range-slider .action.small:focus, .product-reviews-summary .action.add:focus, .smile-es-range-slider .action.small:hover, .product-reviews-summary .action.add:hover {
  color: #97999C;
  background: transparent;
}

.smile-es-range-slider .action.small:after, .product-reviews-summary .action.add:after {
  margin-top: 2px;
  margin-left: 16px;
}

.filter-options-item .filter-options-content .action.show-more {
  position: relative;
  text-decoration: none;
  background: transparent;
  color: #000;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0 0;
}

.filter-options-item .filter-options-content .action.show-more:focus, .filter-options-item .filter-options-content .action.show-more:hover {
  color: #97999C;
  background: transparent;
}

.filter-options-item .filter-options-content .action.show-more:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.widget.block-products-list .block-title,
.widget.block-new-products .block-title,
.widget.block-new-products-list .block-title,
.widget.block-new-products-images .block-title,
.widget.block-new-products-names .block-title,
.widget.block-viewed-products-grid .block-title,
.widget.block-viewed-products-list .block-title,
.widget.block-viewed-products-names .block-title,
.widget.block-viewed-products-images .block-title,
.block.upsell .block-title,
.block.crosssell .block-title,
.block.related .block-title, .catalog-category-view .page-title,
.catalogsearch-result-index .page-title, .review-list .block-title {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
}

.pages-items > .item > a.action,
.pages-items > .item > .page.action {
  transition: background-color 0.225s cubic-bezier(0, 0, 0.2, 1);
  will-change: background-color;
  backface-visibility: hidden;
}

.filter-options-title:after,
.filter-title:after {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.action.sorter-action, .filter-content, .product-item > .product-item-info .product-item-photo, .product-item > .product-item-info .product-item-details, .product-item [class^="swatch-opt-"] {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1), opacity 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.catalog-product-view .product.info.detailed {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.widget.block-products-list .block-title,
.widget.block-new-products .block-title,
.widget.block-new-products-list .block-title,
.widget.block-new-products-images .block-title,
.widget.block-new-products-names .block-title,
.widget.block-viewed-products-grid .block-title,
.widget.block-viewed-products-list .block-title,
.widget.block-viewed-products-names .block-title,
.widget.block-viewed-products-images .block-title,
.block.upsell .block-title,
.block.crosssell .block-title,
.block.related .block-title {
  text-align: center;
  font-weight: 600;
}

.widget.block-products-list .block-title,
.widget.block-new-products .block-title,
.widget.block-new-products-list .block-title,
.widget.block-new-products-images .block-title,
.widget.block-new-products-names .block-title,
.widget.block-viewed-products-grid .block-title,
.widget.block-viewed-products-list .block-title,
.widget.block-viewed-products-names .block-title,
.widget.block-viewed-products-images .block-title,
.block.upsell .block-title,
.block.crosssell .block-title,
.block.related .block-title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .widget.block-products-list .block-title,
  .widget.block-new-products .block-title,
  .widget.block-new-products-list .block-title,
  .widget.block-new-products-images .block-title,
  .widget.block-new-products-names .block-title,
  .widget.block-viewed-products-grid .block-title,
  .widget.block-viewed-products-list .block-title,
  .widget.block-viewed-products-names .block-title,
  .widget.block-viewed-products-images .block-title,
  .block.upsell .block-title,
  .block.crosssell .block-title,
  .block.related .block-title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .widget.block-products-list .block-title,
  .widget.block-new-products .block-title,
  .widget.block-new-products-list .block-title,
  .widget.block-new-products-images .block-title,
  .widget.block-new-products-names .block-title,
  .widget.block-viewed-products-grid .block-title,
  .widget.block-viewed-products-list .block-title,
  .widget.block-viewed-products-names .block-title,
  .widget.block-viewed-products-images .block-title,
  .block.upsell .block-title,
  .block.crosssell .block-title,
  .block.related .block-title {
    font-size: 25px;
  }
}

.widget.block-products-list .block-title,
.widget.block-new-products .block-title,
.widget.block-new-products-list .block-title,
.widget.block-new-products-images .block-title,
.widget.block-new-products-names .block-title,
.widget.block-viewed-products-grid .block-title,
.widget.block-viewed-products-list .block-title,
.widget.block-viewed-products-names .block-title,
.widget.block-viewed-products-images .block-title,
.block.upsell .block-title,
.block.crosssell .block-title,
.block.related .block-title {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {
  .widget.block-products-list .block-title,
  .widget.block-new-products .block-title,
  .widget.block-new-products-list .block-title,
  .widget.block-new-products-images .block-title,
  .widget.block-new-products-names .block-title,
  .widget.block-viewed-products-grid .block-title,
  .widget.block-viewed-products-list .block-title,
  .widget.block-viewed-products-names .block-title,
  .widget.block-viewed-products-images .block-title,
  .block.upsell .block-title,
  .block.crosssell .block-title,
  .block.related .block-title {
    margin-bottom: 3rem;
  }
}

.block.upsell:not(.cms-slider-block),
.block.crosssell:not(.cms-slider-block),
.block.related:not(.cms-slider-block),
.block.block-widget:not(.cms-slider-block) {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 992px) {
  .block.upsell:not(.cms-slider-block),
  .block.crosssell:not(.cms-slider-block),
  .block.related:not(.cms-slider-block),
  .block.block-widget:not(.cms-slider-block) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.smile-es-range-slider .action.small, .product-reviews-summary .action.add {
  background: transparent;
  color: #000;
  text-transform: none;
  font-weight: 800;
  padding: 0 5px;
  letter-spacing: -0.02em;
  position: relative;
  display: inline-block;
}

.smile-es-range-slider .action.small:after, .product-reviews-summary .action.add:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.smile-es-range-slider .action.small:focus, .product-reviews-summary .action.add:focus, .smile-es-range-slider .action.small:hover, .product-reviews-summary .action.add:hover {
  color: #97999C;
  background: transparent;
}

.smile-es-range-slider .action.small:after, .product-reviews-summary .action.add:after {
  margin-top: 0;
  margin-left: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  font-size: inherit;
}

.catalog-category-view .page-title,
.catalogsearch-result-index .page-title {
  color: black;
  margin-bottom: 1.8rem;
}

.catalog-category-view,
.catalogsearch-result-index {
  background: #F5F7F6;
}

.catalog-category-view .page-main .sidebar,
.catalogsearch-result-index .page-main .sidebar {
  margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
  .catalog-category-view.page-layout-3columns .columns, .catalog-category-view.page-layout-2columns-right .columns, .catalog-category-view.page-layout-2columns-left .columns,
  .catalogsearch-result-index.page-layout-3columns .columns,
  .catalogsearch-result-index.page-layout-2columns-right .columns,
  .catalogsearch-result-index.page-layout-2columns-left .columns {
    align-items: flex-start;
  }
}

@media screen and (min-width: 992px) {
  .catalog-category-view.page-layout-3columns .main, .catalog-category-view.page-layout-2columns-right .main, .catalog-category-view.page-layout-2columns-left .main,
  .catalogsearch-result-index.page-layout-3columns .main,
  .catalogsearch-result-index.page-layout-2columns-right .main,
  .catalogsearch-result-index.page-layout-2columns-left .main {
    width: calc(100% - 302px);
  }
}

@media screen and (min-width: 1440px) {
  .catalog-category-view.page-layout-3columns .main, .catalog-category-view.page-layout-2columns-right .main, .catalog-category-view.page-layout-2columns-left .main,
  .catalogsearch-result-index.page-layout-3columns .main,
  .catalogsearch-result-index.page-layout-2columns-right .main,
  .catalogsearch-result-index.page-layout-2columns-left .main {
    width: calc(100% - 352px);
  }
}

@media screen and (min-width: 992px) {
  .catalog-category-view.page-layout-3columns .sidebar-container, .catalog-category-view.page-layout-2columns-right .sidebar-container, .catalog-category-view.page-layout-2columns-left .sidebar-container,
  .catalogsearch-result-index.page-layout-3columns .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-right .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-left .sidebar-container {
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 10px;
    z-index: 1020;
    z-index: 1;
    width: 284px;
    margin-right: 1.125rem;
  }
}

@media screen and (min-width: 1440px) {
  .catalog-category-view.page-layout-3columns .sidebar-container, .catalog-category-view.page-layout-2columns-right .sidebar-container, .catalog-category-view.page-layout-2columns-left .sidebar-container,
  .catalogsearch-result-index.page-layout-3columns .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-right .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-left .sidebar-container {
    width: 334px;
  }
}

.product-options-wrapper,
.product-options-bottom,
.attribute.overview,
.attribute.overview,
.product.alert {
  margin-bottom: 3rem;
}

.price-tier_price > div:not(:empty),
.product-info-stock-sku {
  margin-bottom: 1.5rem;
}

.catalog-product-view {
  background: #fff;
}

.catalog-product-view .column.main {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-wrap: wrap;
}

.catalog-product-view .product-info-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
}

@media screen and (min-width: 992px) {
  .catalog-product-view .product-info-wrapper {
    flex-direction: row;
  }
}

.catalog-product-view .product-info-main {
  width: 100%;
  order: 2;
}

@media screen and (min-width: 992px) {
  .catalog-product-view .product-info-main {
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-left: 1.5rem;
    width: 38.1963%;
  }
}

.catalog-product-view .product.media {
  width: 100%;
  background: #fff;
  order: 1;
  margin-bottom: 0.75rem;
}

@media screen and (min-width: 992px) {
  .catalog-product-view .product.media {
    width: 61.8037%;
    margin-bottom: 0;
  }
}

.catalog-product-view .product.info {
  width: 100%;
  order: 3;
}

.catalog-product-view .block.related {
  order: 4;
  width: 100%;
}

.catalog-product-view .block.upsell {
  order: 5;
  width: 100%;
}

.catalog-product-view:not(.page-product-configurable) .product-add-form > form,
.catalog-product-view .product-options-bottom {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 3rem 0 0.75rem;
}

.catalog-product-view:not(.page-product-configurable) .product-add-form > form .product-info-price,
.catalog-product-view:not(.page-product-configurable) .product-add-form > form .box-tocart,
.catalog-product-view .product-options-bottom .product-info-price,
.catalog-product-view .product-options-bottom .box-tocart {
  width: 50%;
}

.catalog-product-view .product-info-price {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  margin-bottom: 100px;
}

.catalog-product-view .product-info-price .price-box {
  text-align: right;
  align-items: flex-end;
}

.catalog-product-view .product-info-price .old-price .price-label,
.catalog-product-view .product-info-price .old-price .price {
  font-size: 1rem;
}

.catalog-product-view .product-info-price [data-price-type="finalPrice"] .price {
  font-weight: 700;
  line-height: 1.1;
}

.catalog-product-view .product-info-price [data-price-type="finalPrice"] .price {
  font-size: 20px;
}

@media screen and (min-width: 0px) {
  .catalog-product-view .product-info-price [data-price-type="finalPrice"] .price {
    font-size: calc(20px + 19 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .catalog-product-view .product-info-price [data-price-type="finalPrice"] .price {
    font-size: 39px;
  }
}

.catalog-product-view .box-tocart {
  margin-bottom: 80px;
}

.catalog-product-view .box-tocart .field.qty {
  max-width: 120px;
}

.catalog-product-view .box-tocart .actions {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
}

.catalog-product-view .box-tocart .action.primary.tocart {
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: none;
}

.catalog-product-view .box-tocart .action.primary.tocart {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .catalog-product-view .box-tocart .action.primary.tocart {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .catalog-product-view .box-tocart .action.primary.tocart {
    font-size: 20px;
  }
}

.catalog-product-view .box-tocart .action.primary.tocart span {
  width: auto;
}

.catalog-product-view .product-social-links {
  text-align: center;
}

.catalog-product-view .product-social-links .product-addto-links {
  display: inline-block;
  vertical-align: middle;
}

.catalog-product-view .product-social-links .action.towishlist,
.catalog-product-view .product-social-links .action.mailto,
.catalog-product-view .product-social-links .action.tocompare {
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 0 0.25rem;
  color: #767676;
  font-size: 0.875rem;
}

.catalog-product-view .product-social-links .action.towishlist:before,
.catalog-product-view .product-social-links .action.mailto:before,
.catalog-product-view .product-social-links .action.tocompare:before {
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 4px;
}

.catalog-product-view .product-social-links .action.towishlist:after,
.catalog-product-view .product-social-links .action.mailto:after,
.catalog-product-view .product-social-links .action.tocompare:after {
  margin-left: 0.5rem;
  content: '|';
}

.catalog-product-view .product-social-links .action.towishlist:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.catalog-product-view .product-social-links .action.tocompare:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.catalog-product-view .product-social-links .action.mailto:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.catalog-product-view .product-social-links .action.mailto > span {
  position: relative;
  top: 2px;
}

.catalog-product-view .product-social-links .action.mailto:after {
  display: none;
}

.catalog-product-view .product.info.detailed,
.catalog-product-view .product-info-wrapper {
  margin-bottom: 3rem;
}

.catalog-product-view .product.info.detailed {
  background: #F5F7F6;
  padding: 3rem 1rem;
}

.catalog-product-view .product.info.detailed > .product.data {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.toolbar-products {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .toolbar-products {
    position: relative;
    z-index: 2;
  }
}

.filter-active .toolbar-products {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .filter-active .toolbar-products {
    margin-top: -15px;
    margin-left: 0;
    border-left: 0 none;
  }
}

.toolbar-products__control, .toolbar-products__pager {
  padding: 0.5rem 1rem;
  min-height: 60px;
  color: inherit;
  background: #fff;
  margin-bottom: 1.5rem;
  font-size: 13px;
}

@media screen and (max-width: 430px) {
  .toolbar-products__control, .toolbar-products__pager {
    flex-direction: column;
    padding: 0.5rem;
    min-height: 0;
    margin-bottom: 0.75rem;
  }
}

.toolbar-products__pager {
  justify-content: space-between;
  display: none;
}

.toolbar-products__pager > .pages {
  width: 50%;
}

.toolbar-products .sorter-label,
.toolbar-products .toolbar-amount {
  display: block;
  margin-bottom: 0;
}

@media screen and (max-width: 430px) {
  .toolbar-products .sorter-label,
  .toolbar-products .toolbar-amount {
    display: none;
    font-size: 0.875rem;
  }
}

.toolbar-products .toolbar-sorter {
  flex: 1 1 auto;
  justify-content: flex-end;
}

@media screen and (max-width: 430px) {
  .toolbar-products .toolbar-sorter {
    font-size: 0.75rem;
  }
}

.toolbar-products .sorter-label {
  font-weight: 700;
  margin-right: 1rem;
}

.toolbar-products select {
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .toolbar-products select {
    height: 32px;
    padding-left: 10px;
    padding-right: 25px;
    background-position-x: calc(100% + 10px);
    background-position-y: 45%;
  }
}

.toolbar-products ~ .toolbar-products {
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 10px;
  z-index: 1020;
  bottom: -1px;
  top: auto;
  z-index: 3;
  width: 100%;
  margin: 0;
}

.toolbar-products ~ .toolbar-products .toolbar-products__control {
  display: none;
}

.toolbar-products ~ .toolbar-products .toolbar-products__pager {
  display: flex;
}

.message + .toolbar-products {
  margin-top: 1.5rem;
}

@media screen and (max-width: 430px) {
  .message + .toolbar-products {
    width: 100%;
    margin-left: 0;
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 430px) {
  .message + .toolbar-products .sorter-label {
    display: block;
  }
}

.modes {
  display: none;
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  .modes {
    display: flex;
  }
}

.modes-label {
  margin-right: 0.5rem;
  display: none;
}

.modes-mode {
  width: 24px;
  height: 24px;
  overflow: hidden;
  display: inline-block;
  color: #eee;
}

.modes-mode.active {
  color: #000;
}

.mode-list:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.mode-grid:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.mode-grid + .mode-list {
  margin-left: 0.5rem;
}

.action.sorter-action {
  box-sizing: border-box;
  padding: 0 0 0 1rem;
  opacity: 1;
}

@media screen and (max-width: 430px) {
  .action.sorter-action {
    padding-left: 0.25rem;
  }
}

.action.sorter-action:hover {
  opacity: 0.3;
}

.action.sorter-action.sort-asc:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.action.sorter-action.sort-desc:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 22px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.pages-items {
  justify-content: flex-start;
  margin: 0;
}

@media screen and (max-width: 430px) {
  .pages-items {
    font-size: 0.75rem;
    justify-content: center;
  }
}

.pages-items > .item > a,
.pages-items > .item > .page {
  width: 36px;
  height: 36px;
  display: block;
  line-height: 36px;
  text-align: center;
  margin: 0 0.5rem;
}

@media screen and (max-width: 992px) {
  .pages-items > .item > a,
  .pages-items > .item > .page {
    margin: 0 0.1rem;
  }
}

.pages-items > .item > a.action,
.pages-items > .item > .page.action {
  width: 36px;
  height: 36px;
  padding: 0;
  box-sizing: border-box;
  background: #eee;
}

.pages-items > .item > a.action:before,
.pages-items > .item > .page.action:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.pages-items > .item > a.action.previous:before,
.pages-items > .item > .page.action.previous:before {
  content: "";
}

.pages-items > .item > a.action:hover,
.pages-items > .item > .page.action:hover {
  background: rgba(238, 238, 238, 0.3);
}

.pages-items > .item:first-child > a,
.pages-items > .item:first-child > .page {
  margin-left: 0;
}

.pages-items > .item:last-child > a,
.pages-items > .item:last-child > .page {
  margin-right: 0;
}

.pages-items > .item.current .page,
.pages-items > .item.current a {
  background: #F5F7F6;
  color: inherit;
}

.limiter {
  justify-content: flex-start;
}

@media screen and (max-width: 430px) {
  .limiter {
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 430px) {
  .limiter {
    display: none !important;
  }
}

.limiter .label {
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 1rem;
}

.limiter .limiter-text {
  display: inline-block;
  margin-left: 1rem;
}

.limiter .limiter-options {
  min-width: 80px;
}

.catalog-product-compare-index .page-title {
  margin: 0.6rem 0;
}

.catalog-product-compare-index .page-title {
  font-size: 16px;
}

@media screen and (min-width: 0px) {
  .catalog-product-compare-index .page-title {
    font-size: calc(16px + 15 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .catalog-product-compare-index .page-title {
    font-size: 31px;
  }
}

@media screen and (min-width: 768px) {
  .catalog-product-compare-index .page-title {
    margin: 1.8rem 0;
  }
}

[data-role="compare-products-link"] {
  padding: 0 0.5rem;
}

.panel.header [data-role="compare-products-link"] {
  padding-right: 1.5rem;
}

[data-role="compare-products-link"] .action.compare {
  position: relative;
  padding: 0;
}

[data-role="compare-products-link"] .action.compare:after {
  bottom: -5px;
}

[data-role="compare-products-link"] .action.compare:active:after {
  position: absolute;
  top: auto;
  opacity: 0;
}

[data-role="compare-products-link"] .counter {
  position: relative;
  vertical-align: top;
  background: #000;
  color: #fff;
  font-size: 12px;
  min-width: 22px;
  padding: 2px 6px 4px 6px;
  border-radius: 1000px;
  text-align: center;
  width: auto;
  text-transform: none;
}

@media screen and (max-width: 991.98px) {
  [data-role="compare-products-link"] .counter {
    vertical-align: middle;
    padding: 0;
    margin-left: 0.5rem;
  }
}

[data-role="compare-products-link"] .counter.empty {
  opacity: 0;
}

.compare-products-wrapper {
  padding: 0;
  margin: 0;
  height: auto;
}

.compare-products-wrapper > li {
  padding: 0;
  margin: 0;
}

.compare-products-wrapper .action {
  overflow: hidden;
  font-size: 0.75rem;
  color: #fff;
  padding: 0.5rem 0 !important;
  text-align: center;
  max-width: none;
  background: #000;
}

@media screen and (max-width: 991.98px) {
  .header.links .link.compare {
    display: none !important;
  }
}

.catalog-product-compare-index .compare-products-wrapper,
.catalog-product-compare-index .item.compare {
  display: none;
}

.catalog-product-compare-index .action.print {
  display: none;
}

.catalog-product_compare-index .page-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .catalog-product_compare-index .page-title {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.table-comparison {
  margin-bottom: 1.5rem;
  table-layout: fixed;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 992px) {
  .table-comparison {
    margin-bottom: 4.5rem;
  }
}

.table-comparison th,
.table-comparison td {
  text-align: center;
  vertical-align: middle;
  padding: 0.7rem;
}

@media screen and (min-width: 992px) {
  .table-comparison th,
  .table-comparison td {
    padding: 0.7rem 1rem;
  }
}

.table-comparison th {
  width: 90px;
}

@media screen and (min-width: 430px) {
  .table-comparison th {
    width: 120px;
  }
}

@media screen and (min-width: 768px) {
  .table-comparison th {
    width: 175px;
  }
}

.table-comparison td {
  width: 170px;
}

@media screen and (min-width: 992px) {
  .table-comparison td {
    width: 275px;
  }
}

.table-comparison__line-divider {
  border-right: 1pt solid #bdbdbd;
}

.table-comparison tr > .table-comparison__line-divider:last-child {
  border-right: 0 none;
}

.table-comparison .price-box {
  align-items: center;
}

.table-comparison__row-bg.is-even {
  background: #F5F7F6;
}

.table-comparison__heading-col {
  position: relative;
}

.table-comparison .secondary-addto-links {
  position: absolute;
  top: 0.7rem;
  right: 17px;
  z-index: 2;
}

.table-comparison .secondary-addto-links .action {
  width: 40px;
  height: 40px;
  padding: 20px 0 0 0;
  overflow: hidden;
  background: #fff;
  border: 1px solid #e9ecef;
  margin-bottom: 0.5rem;
  text-align: center;
  display: flex;
}

.table-comparison .secondary-addto-links .delete:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.table-comparison .secondary-addto-links .delete span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.table-comparison .secondary-addto-links .delete:after {
  margin: 0;
  color: #b70e0e;
}

.table-comparison .secondary-addto-links .delete:active, .table-comparison .secondary-addto-links .delete:hover {
  background: #b70e0e;
  color: #fff;
}

.table-comparison .secondary-addto-links .delete:active:after, .table-comparison .secondary-addto-links .delete:hover:after {
  color: #fff;
}

.table-comparison .secondary-addto-links .towishlist:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.table-comparison .secondary-addto-links .towishlist span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.table-comparison .secondary-addto-links .towishlist:after {
  margin: 0;
}

.table-comparison .secondary-addto-links .towishlist:active, .table-comparison .secondary-addto-links .towishlist:hover {
  background: #000;
  color: #fff;
}

.table-comparison .secondary-addto-links .towishlist:active:after, .table-comparison .secondary-addto-links .towishlist:hover:after {
  color: #fff;
}

.table-comparison .product-item-name {
  display: block;
  margin-bottom: 0;
  word-wrap: break-word;
}

.table-comparison .product-item-photo {
  display: block;
  overflow: hidden;
}

.table-comparison .action.primary {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.table-comparison th,
.table-comparison .attribute-value {
  font-size: 0.875rem;
  word-wrap: break-word;
}

.table-comparison .filter-controls {
  vertical-align: bottom;
}

.table-comparison--show-differences tr:not(.is-row-different) {
  display: none;
}

.table-comparison thead th {
  padding: 0;
  height: 0;
  overflow: hidden;
}

[for="hide-similar-properties"] {
  text-align: left;
}

.product-comparison {
  width: 100%;
  overflow: auto;
}

.product-comparison .u-sticky {
  top: 0;
}

.product-comparison .u-sticky .table-comparison {
  box-shadow: 0px 2px 1px #efefef;
}

.product-comparison .u-sticky tbody > tr:first-child {
  background: #fff;
}

.filter {
  color: #000;
  background: #fff;
  padding: 1rem;
}

@media screen and (min-width: 992px) {
  .filter {
    padding: 1.875rem;
  }
}

.filter.block:not(.active) {
  width: 100%;
}

.filter .item > a:hover {
  text-decoration: none;
}

.filter-options-title, .filter-subtitle,
.filter-current-subtitle,
.filter-title {
  font-size: 1rem;
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  color: #000;
}

@media screen and (min-width: 992px) {
  .filter-options-title, .filter-subtitle,
  .filter-current-subtitle,
  .filter-title {
    font-weight: normal;
  }
}

.filter-options-title,
.filter-title {
  position: relative;
}

.filter-options-title:after,
.filter-title:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.filter-options-title:focus,
.filter-title:focus {
  outline: none;
}

.filter-options-title:after,
.filter-title:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -0.5rem;
  font-size: 1rem;
}

.filter-options-title[aria-expanded=true]:after, .filter-options-title.active:after,
.filter-title[aria-expanded=true]:after,
.filter-title.active:after {
  content: "";
}

.filter-title:after {
  content: "";
  font-size: 1rem;
  margin-top: -0.5rem;
}

.filter-current-subtitle {
  font-size: 0.8rem;
}

@media screen and (min-width: 992px) {
  .filter-current-subtitle {
    font-size: 1rem;
  }
}

.filter-options-title {
  font-size: 1rem;
}

.filter-options-title:after {
  margin-left: -4px;
}

@media screen and (min-width: 992px) {
  .filter-options-title:after {
    margin-left: 0;
    content: "";
  }
}

.filter-options-item {
  padding: 0 0 1rem 0;
}

.filter-options-item .filter-options-content {
  padding: 1rem 0 0 0;
}

@media screen and (min-width: 992px) {
  .filter-options-item .filter-options-content {
    padding: 1rem 0;
  }
}

.filter-options-item .filter-options-content .count:before {
  content: '(';
  margin-left: 4px;
}

.filter-options-item .filter-options-content .count:after {
  content: ')';
}

.filter-options-item .filter-options-content .no-results-message {
  font-size: 0.875rem;
  color: #767676;
  text-align: center;
  margin: 1rem 0;
}

.filter-options-item .filter-options-content .actions {
  text-align: center;
}

.filter-options-item .filter-options-content .actions > .secondary {
  display: inline-block;
}

.filter-options-item .filter-options-content .action.show-more {
  width: auto;
}

.filter-options-item .filter-options-content .field.search .control {
  position: relative;
}

.filter-options-item .filter-options-content .field.search .control:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.filter-options-item .filter-options-content .field.search .control:before {
  position: absolute;
  top: 50%;
  right: 1px;
  padding: 0 1rem;
  transform: translateY(-50%);
  background: #fff;
  color: #767676;
}

.filter-options-item .filter-options-content .filter-search {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.filter-options-item .item {
  color: #000;
  font-size: 13px;
  border-bottom: 0 none;
  padding: 0.25rem 0;
}

.filter-title strong {
  display: block;
  width: 100%;
}

.filter-title strong[aria-expanded=true] {
  margin-bottom: 1rem;
}

.filter-title strong:focus {
  outline: 0 none;
}

.filter-title:after {
  pointer-events: none;
}

.active .filter-title:after {
  content: "";
  font-size: 16px;
  top: -6px;
  margin-top: 8px;
}

@media screen and (min-width: 992px) {
  .filter-title {
    display: none;
  }
}

.filter-content {
  display: block;
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
}

@media screen and (min-width: 992px) {
  .filter-content {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    max-height: none;
    transform: translateY(0px);
  }
}

.active .filter-content {
  visibility: visible;
  overflow: visible;
  opacity: 1;
  max-height: 8000px;
  transform: translateY(0px);
}

.filter-content .items {
  padding: 0;
  margin: 0;
  list-style: none;
}

.filter-current {
  padding: 1rem 1rem 0 1rem;
  border: 1px solid #767676;
  border-bottom: 0 none;
}

.filter-current .filter-current-subtitle {
  display: block;
  padding-bottom: 1rem;
}

.filter-current + .filter-actions {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #767676;
  border-top: 0 none;
}

@media screen and (min-width: 992px) {
  .filter-current + .filter-actions {
    padding-top: 0;
    margin-bottom: 2rem;
  }
}

.filter-current .items > .item {
  padding: 0.25rem 0.25rem;
}

.filter-current .item {
  justify-content: flex-start;
}

.filter-current .filter-value {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #767676;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .filter-current .filter-value {
    max-width: 222px;
  }
}

.filter-current .filter-value > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.filter-current .filter-value:hover, .filter-current .filter-value:active, .filter-current .filter-value:focus, .filter-current .filter-value:visited {
  text-decoration: none;
}

.filter-current .filter-value:active {
  outline: none;
}

.filter-current .filter-value::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.filter-current .filter-value.disabled, .filter-current .filter-value[disabled],
fieldset[disabled] .filter-current .filter-value {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.filter-current .filter-value:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.filter-current .filter-value span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.filter-current .filter-value:before {
  font-size: 12px;
  margin-right: 8px;
}

.filter-current .action.remove {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  box-sizing: border-box;
  float: right;
  margin-left: auto;
  color: #767676;
}

@media screen and (max-width: 768px) {
  .filter-current .action.remove {
    max-width: 222px;
  }
}

.filter-current .action.remove > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.filter-current .action.remove:hover, .filter-current .action.remove:active, .filter-current .action.remove:focus, .filter-current .action.remove:visited {
  text-decoration: none;
}

.filter-current .action.remove:active {
  outline: none;
}

.filter-current .action.remove::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.filter-current .action.remove.disabled, .filter-current .action.remove[disabled],
fieldset[disabled] .filter-current .action.remove {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.filter-current .action.remove:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.filter-current .action.remove span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

@media screen and (min-width: 992px) {
  .filter-current .action.remove {
    float: none;
    margin-left: 8px;
  }
}

.action.filter-clear {
  padding: 0;
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .action.filter-clear {
    text-align: right;
  }
}

.filter-options input[type="checkbox"] + label {
  color: #000;
  margin-bottom: 0;
}

.filter-options input[type="checkbox"] + label:before {
  margin-right: 0.625rem;
}

.filter-content .swatch-option {
  height: 30px;
}

.filter-options > .filter-options-item:first-child {
  padding-top: 0;
}

.filter-options > .filter-options-item:last-child {
  padding-bottom: 0;
}

.smile-es-range-slider {
  justify-content: space-between;
  flex-wrap: wrap;
}

.smile-es-range-slider [data-role="from-label"],
.smile-es-range-slider [data-role="message-box"],
.smile-es-range-slider [data-role="to-label"] {
  color: #767676;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.smile-es-range-slider .ui-slider {
  width: 100%;
}

.smile-es-range-slider .actions-toolbar {
  width: 100%;
  margin: 0;
}

.smile-es-range-slider [data-role="message-box"] {
  text-align: left;
  color: #000;
}

.smile-es-range-slider .actions-toolbar {
  justify-content: space-between;
}

.smile-es-range-slider .action.small {
  font-size: 0.875rem;
  padding-right: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 992px) {
  .smile-es-range-slider .action.small {
    padding-bottom: 14px;
  }
}

.smile-es-range-slider .action.small:after {
  position: relative;
  top: 2px;
  font-size: 100%;
  margin-left: 0;
}

.ui-slider-horizontal {
  margin: 0.5rem 0;
  background: #000;
}

.filter-content .ui-slider-horizontal {
  margin: 0.5rem 1rem;
}

.ui-slider-handle {
  width: 30px;
  height: 30px;
  top: 50%;
  margin-top: -15px;
  border-radius: 1000px;
  background: #fff;
  border: 6px solid #000;
}

.ui-slider-handle:focus {
  outline: 0 none;
}

.ui-slider-handle.ui-state-focus, .ui-slider-handle.ui-state-hover {
  border-color: #b0aa00;
}

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -15px;
}

.products-grid .product-items > .product-item {
  padding: 0 0.5rem 1rem 0.5rem;
  width: 50%;
  padding: 0 0.5rem 1rem 0.5rem;
  padding: 0 0.5rem 1rem 0.5rem;
}

@media screen and (max-width: 430px) {
  .products-grid .product-items > .product-item {
    padding: 0 3px 6px 3px;
  }
}

@media screen and (min-width: 992px) {
  .products-grid .product-items > .product-item {
    width: 33.33333%;
  }
}

@media screen and (max-width: 430px) {
  .products-grid .product-items > .product-item {
    padding: 0 3px 6px 3px;
  }
}

@media screen and (min-width: 1200px) {
  .products-grid .product-items > .product-item {
    width: 25%;
  }
}

@media screen and (max-width: 430px) {
  .products-grid .product-items > .product-item {
    padding: 0 3px 6px 3px;
  }
}

.products-grid .product-item-photo {
  margin-bottom: 1rem;
  width: 100%;
}

.products-grid .product-item-info .product-item-details,
.products-grid .product-item-info {
  display: flex;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}

.products-grid .product-item-info .product-item-details {
  flex: 1 1 auto;
  height: auto;
}

.products-grid .product-item-info .product-item-details > * {
  width: 100%;
}

.products-grid .product-item-info .product-item-details > .product-item-name {
  flex: 1 1 auto;
  max-width: 100%;
}

.products-grid .product-item-info .product-item-details .product-item-inner {
  margin-top: auto;
}

.products-grid .product-reviews-summary {
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: none;
  visibility: hidden;
}

.products-grid .product-reviews-summary .reviews-actions {
  display: none;
}

.products-grid .product-item-name > .product-reviews-summary {
  visibility: visible;
  display: block;
}

.products-list .product-item {
  width: 100%;
  margin-bottom: 1.5rem;
}

.products-list .product-item:last-child {
  margin-bottom: 0;
}

.products-list .product-item .product-item-inner,
.products-list .product-item [class^='swatch-opt-'] {
  position: relative;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.products-list .product-item-info {
  align-items: flex-start;
  background: white;
  padding: 14px;
}

@media screen and (min-width: 1200px) {
  .products-list .product-item-info {
    padding: 2rem;
  }
}

@media screen and (max-width: 430px) {
  .products-list .product-item-info {
    flex-direction: column;
    align-items: center;
  }
}

.products-list .product-item-info .product-item-details {
  flex: 1 1 auto;
  padding-left: 1rem;
}

@media screen and (min-width: 768px) {
  .products-list .product-item-info .product-item-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .products-list .product-item-info .product-item-details {
    padding: 0;
    width: 100%;
  }
}

.products-list .product-item-info .product-item-inner {
  padding-top: 1rem;
  padding-left: 0;
  padding-right: 0;
  margin-top: auto;
}

.products-list .product-item-photo {
  width: 100%;
}

@media screen and (min-width: 430px) {
  .products-list .product-item-photo {
    width: 200px;
    min-width: 200px;
  }
}

@media screen and (min-width: 768px) {
  .products-list .product-item-photo {
    width: 270px;
    min-width: 270px;
  }
}

.products-list .product-item-description {
  width: 100%;
  order: 1;
}

.products-list .product-item-description .action.more {
  display: inline-block;
  padding: 0.5rem;
  color: #767676;
  font-size: 0.875rem;
}

@media screen and (max-width: 768px) {
  .products-list .product-item-description .action.more {
    font-size: 0.75rem;
  }
}

.products-list .product-item-actions {
  width: 200px;
  margin-right: auto;
  order: 2;
}

@media screen and (max-width: 430px) {
  .products-list .product-item-actions {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .products-list .product-item-actions .action.tocart {
    max-width: none;
  }
}

.products-list .price-box {
  margin-bottom: 1rem;
}

.product-items {
  margin: 0 0 1.5rem 0;
  padding: 0;
  list-style: none;
}

.product-items:not(.slick-slider) {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

.products-grid {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.product-item {
  position: relative;
  z-index: 1;
}

.product-item .product-image-wrapper {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
}

.product-item .product-image-position {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-item .product-item-photo {
  display: block;
  text-align: center;
  background: #fff;
  overflow: hidden;
}

.product-item .product-image-photo {
  max-width: 100%;
  display: block;
}

.product-item .product-item-name {
  display: block;
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.product-item .product-item-actions {
  margin-top: 1rem;
}

.product-item .product-item-actions .actions-primary .stock.unavailable {
  display: none;
}

.product-item .product-item-actions .actions-secondary {
  justify-content: space-between;
}

.product-item .product-item-actions .actions-secondary .action {
  padding: 0 0.5rem;
}

.product-item .action.towishlist {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 768px) {
  .product-item .action.towishlist {
    max-width: 222px;
  }
}

.product-item .action.towishlist > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.product-item .action.towishlist:hover, .product-item .action.towishlist:active, .product-item .action.towishlist:focus, .product-item .action.towishlist:visited {
  text-decoration: none;
}

.product-item .action.towishlist:active {
  outline: none;
}

.product-item .action.towishlist::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.product-item .action.towishlist.disabled, .product-item .action.towishlist[disabled],
fieldset[disabled] .product-item .action.towishlist {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.product-item .action.towishlist:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.product-item .action.towishlist span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.product-item .action.tocompare {
  display: flex;
  width: auto;
  font-family: inherit;
  font-size: 100%;
  white-space: normal;
  word-spacing: normal;
  cursor: pointer;
  border: 0 none;
  margin: 0;
  padding: 0 auto;
  background-image: none;
  border-radius: 0;
  border-radius: 0;
  border: 0 none;
  box-sizing: border-box;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 768px) {
  .product-item .action.tocompare {
    max-width: 222px;
  }
}

.product-item .action.tocompare > span {
  white-space: normal;
  word-spacing: normal;
  width: 100%;
  overflow: hidden;
}

.product-item .action.tocompare:hover, .product-item .action.tocompare:active, .product-item .action.tocompare:focus, .product-item .action.tocompare:visited {
  text-decoration: none;
}

.product-item .action.tocompare:active {
  outline: none;
}

.product-item .action.tocompare::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}

.product-item .action.tocompare.disabled, .product-item .action.tocompare[disabled],
fieldset[disabled] .product-item .action.tocompare {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.product-item .action.tocompare:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.product-item .action.tocompare span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.product-item .action.tocart {
  width: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 430px) {
  .product-item .action.tocart {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.75rem;
  }
}

.product-item > .product-item-info {
  position: relative;
  z-index: 2;
  border: 1px solid transparent;
  border-bottom: 0 none;
  overflow: hidden;
  transition: box-shadow 0.195s cubic-bezier(0.4, 0, 1, 1);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0);
  background: #fff;
  padding: 14px;
}

@media screen and (min-width: 768px) {
  .product-item > .product-item-info {
    padding: 1rem;
  }
}

.product-item > .product-item-info .product-item-photo {
  transform: translateY(0%);
}

.product-item > .product-item-info .product-item-details {
  padding: 0;
  transform: translateY(0%);
  background: rgba(255, 255, 255, 0);
}

@media screen and (min-width: 768px) {
  .product-item > .product-item-info .product-item-details {
    padding: 1rem;
    padding-top: 0;
  }
}

.product-info-main .product-reviews-summary {
  margin-bottom: 0.75rem;
}

.product-reviews-summary .rating-summary {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .product-reviews-summary .rating-summary {
    width: 100px;
  }
}

.product-reviews-summary .reviews-actions {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .product-reviews-summary .reviews-actions {
    width: calc(100% - 124px);
  }
}

.product-reviews-summary .action.view {
  display: inline-block;
  width: auto;
  padding: 0;
  text-transform: none;
  font-size: 0.875rem;
  color: #767676;
  vertical-align: top;
  letter-spacing: 0;
}

.product-reviews-summary .action.view:after {
  display: none;
}

@media screen and (max-width: 430px) {
  .product-reviews-summary .action.view {
    width: 100%;
    text-align: left;
  }
}

@media screen and (min-width: 430px) {
  .product-reviews-summary .action.view + .action.add {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 430px) {
  .product-reviews-summary .action.view + .action.add:before {
    content: '|';
    margin-right: 1rem;
  }
}

.product-reviews-summary .action.add {
  display: inline-block;
  vertical-align: top;
  font-size: 0.75rem;
  width: auto;
  box-sizing: border-box;
  padding: 0;
  margin-left: 0;
  line-height: 22px;
  color: #767676;
}

@media screen and (max-width: 430px) {
  .product-reviews-summary .action.add {
    width: 100%;
    text-align: left;
  }
}

.catalog-product-view .page-title {
  margin-bottom: 1.2rem;
}

.catalog-product-view .page-title {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
}

.catalog-product-view .page-title {
  font-size: 20px;
}

@media screen and (min-width: 0px) {
  .catalog-product-view .page-title {
    font-size: calc(20px + 19 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .catalog-product-view .page-title {
    font-size: 39px;
  }
}

.product-info-stock-sku {
  justify-content: space-between;
}

.product.sku {
  padding: 0.5rem;
  font-size: 0.75rem;
  color: inherit;
  border: 1px solid rgba(221, 220, 219, 0.5);
}

.product.sku .type {
  padding-right: 0.5rem;
  font-weight: normal;
  color: #767676;
}

.product.sku .value {
  font-weight: 800;
}

.stock {
  padding: 0 0.5rem;
  font-size: inherit;
  color: inherit;
}

.stock:after {
  position: relative;
  top: 1px;
  margin-left: 3px;
}

.stock.available {
  background: #e0ffcf;
}

.stock.available:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.stock.unavailable {
  background: #ffbebe;
}

.stock.unavailable:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.stock.unavailable:after {
  top: 4px;
}

.product-add-form .product-options-wrapper {
  border: 1px solid rgba(221, 220, 219, 0.5);
  padding: 1.5rem 1rem;
}

.product-add-form .product-options-wrapper .swatch-attribute-options,
.product-add-form .product-options-wrapper .swatch-opt {
  margin: 0;
}

.product-add-form .product-options-wrapper .swatch-attribute {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: 1.5rem;
}

.product-add-form .product-options-wrapper .swatch-opt > .swatch-attribute:last-child {
  margin-bottom: 0;
}

.product-add-form .product-options-wrapper .swatch-option {
  margin-bottom: 0;
  height: 30px;
}

.fotorama [role="button"] {
  -webkit-appearance: none;
  border: 0 none;
  text-align: left;
}

.fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  left: 0;
}

.fotorama.fotorama--fullscreen {
  z-index: 1090 !important;
}

.fotorama .fotorama__nav--dots .fotorama__nav__frame {
  width: 24px;
  height: 46px;
  margin-top: 0.5rem;
}

.fotorama .fotorama__dot {
  width: 18px;
  height: 18px;
  border-radius: 1000px;
  border-color: #eee;
}

.fotorama .fotorama__active .fotorama__dot {
  background-color: #eee;
  border-color: #eee;
}

.fotorama .fotorama__nav {
  padding: 0 30px;
}

.fotorama__nav-wrap .fotorama__thumb-border {
  border: 2px solid #eee;
}

@media screen and (min-width: 992px) {
  .gallery-placeholder .fotorama__wrap {
    width: 500px;
    height: 500px;
  }
}

.gallery-placeholder .loading-mask {
  position: relative;
}

.review-add .block-title {
  display: none;
}

.review-legend span {
  display: block;
}

.label + br {
  display: none;
}

.review-field-ratings .label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}

.review-field-ratings .label > span {
  position: relative;
}

.review-field-ratings .label > span:after {
  content: '*';
  margin-left: 4px;
  color: #b70e0e;
}

@media screen and (max-width: 430px) {
  .review-form-actions .action.primary {
    width: 100%;
    box-sizing: border-box;
    font-size: 0.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.review-control-vote {
  color: #dddcdb;
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 1.5rem;
}

.review-control-vote:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.review-control-vote:before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  color: #ded31e;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 1px;
  z-index: 1;
  color: #dddcdb;
  color: #dddcdb;
  color: #dddcdb;
  color: #dddcdb;
  color: #dddcdb;
  content: "";
}

.review-control-vote label {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  cursor: pointer;
  z-index: 2;
}

.review-control-vote label:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.review-control-vote label:before {
  color: #ded31e;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 1px;
  opacity: 0;
}

.review-control-vote label:hover:before {
  opacity: 1;
}

.review-control-vote .rating-1 {
  z-index: 7;
}

.review-control-vote .rating-1:before {
  content: "";
}

.review-control-vote .rating-2 {
  z-index: 6;
}

.review-control-vote .rating-2:before {
  content: "";
}

.review-control-vote .rating-3 {
  z-index: 5;
}

.review-control-vote .rating-3:before {
  content: "";
}

.review-control-vote .rating-4 {
  z-index: 4;
}

.review-control-vote .rating-4:before {
  content: "";
}

.review-control-vote .rating-5 {
  z-index: 3;
}

.review-control-vote .rating-5:before {
  content: "";
}

.review-control-vote .radio {
  display: none;
}

.review-control-vote .radio:checked + label:before {
  opacity: 1;
}

.review-list .block-title {
  margin-bottom: 1rem;
}

.review-list .block-title {
  font-size: 16px;
}

@media screen and (min-width: 0px) {
  .review-list .block-title {
    font-size: calc(16px + 15 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .review-list .block-title {
    font-size: 31px;
  }
}

.review-list .review-title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0.5rem;
}

.review-list .review-item {
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin-bottom: 3rem;
  background-color: #FBFBFB;
}

@media screen and (min-width: 992px) {
  .review-list .review-item {
    padding: 1.5rem 3rem;
    flex-direction: row;
  }
}

.review-list .review-item .review-title {
  width: 100%;
}

.review-list .review-item .review-ratings {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {
  .review-list .review-item .review-ratings {
    width: 30%;
    margin-bottom: 0;
  }
}

.review-list .review-item .review-details {
  color: #767676;
}

@media screen and (min-width: 992px) {
  .review-list .review-item .review-details {
    margin-left: 30%;
    width: 70%;
  }
}

.review-list .review-item .review-details .review-author {
  margin-right: 1rem;
}

.review-list .review-item .review-details .review-author,
.review-list .review-item .review-details .review-date {
  display: inline-block;
  margin-bottom: 0;
}

.review-list .review-item .review-content {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {
  .review-list .review-item .review-content {
    width: 70%;
  }
}

.review-items {
  list-style: none;
  padding: 0;
}

.review-toolbar {
  display: none;
}

.review-items + .review-toolbar {
  display: block;
  margin-bottom: 3rem;
}

.product.attribute.overview .value > div {
  display: inline;
}

.product.attribute.overview .value a {
  white-space: nowrap;
}

.overview-readmore-link {
  margin-left: 0.5rem;
}

.overview-readmore-link:before {
  content: '- ';
}

.product-item .swatch-option {
  height: 30px;
}

@media screen and (max-width: 430px) {
  .product-item .swatch-option {
    height: 24px !important;
    width: 24px !important;
    min-width: 24px !important;
    font-size: 0.75rem !important;
    padding: 0 !important;
  }
}

.product-item [class^="swatch-opt-"] {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  visibility: hidden;
  z-index: 3;
  padding: 1rem;
  opacity: 0;
  transform: translateY(0%);
  transition-duration: 0.2s;
}

.product-item [class^="swatch-opt-"]:before {
  content: 'Choose options:';
  display: block;
  font-weight: 800;
}

@media screen and (max-width: 430px) {
  .product-item [class^="swatch-opt-"]:before {
    font-size: 0.75rem;
  }
}

.product-item .show-swatches {
  position: relative;
}

.product-item .show-swatches [class^="swatch-opt-"] {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
  .product-item .show-swatches [class^="swatch-opt-"] {
    transform: translateY(-20%);
  }
}

body .product-item-photo.is-loading {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1089;
  z-index: 1;
}

body .product-item-photo.is-loading:before {
  background-image: url("../../images/loaders/invert/ball-triangle.svg");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  z-index: 1090;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .product-item-photo.is-loading:before {
    background-image: url("../../images/loaders/invert/ball-triangle.gif");
  }
}

body .product-item-photo.is-loading:before {
  z-index: 2;
}

body .swatch-option-loading {
  content: '';
}

@media screen and (min-width: 992px) {
  .product.data.items[role="tablist"] > [role="tab"] {
    float: left;
    width: auto;
    margin: 0 2px;
  }
}

@media screen and (min-width: 992px) {
  .product.data.items[role="tablist"] > [role="tab"]:first-child {
    margin-left: 0;
  }
}

.product.data.items[role="tablist"] [role="tabpanel"]:not(.slick-slide) {
  padding: 0 1.5rem 1rem 1.5rem;
  background: #fff;
}

@media screen and (min-width: 992px) {
  .product.data.items[role="tablist"] [role="tabpanel"]:not(.slick-slide) {
    padding: 3rem 10%;
    float: right;
    margin-left: -100%;
    width: 100%;
    margin-top: 54px;
  }
}

.product.data.items[role="tablist"] [role="tabpanel"]:not(.slick-slide) .value p:last-of-type {
  margin-bottom: 0;
}

.product.data.items[role="tablist"] .switch {
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
  background: #eee;
  padding: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

.product.data.items[role="tablist"] .switch:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.product.data.items[role="tablist"] .switch {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .product.data.items[role="tablist"] .switch {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .product.data.items[role="tablist"] .switch {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .product.data.items[role="tablist"] .switch {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 992px) {
  .product.data.items[role="tablist"] .switch {
    padding: 1rem 2rem;
  }
}

.product.data.items[role="tablist"] .switch:hover {
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .product.data.items[role="tablist"] .switch:after {
    display: none;
  }
}

.product.data.items[role="tablist"] .switch .counter {
  padding: 0.25rem 0.5rem;
  border-radius: 1000px;
  font-size: 0.75rem;
  background: #dddcdb;
  text-align: center;
  line-height: 0.75rem;
  margin-left: 5px;
  position: relative;
  top: -4px;
}

@media screen and (max-width: 992px) {
  .product.data.items[role="tablist"] .switch .counter {
    display: none;
  }
}

.product.data.items[role="tablist"] .title.active .switch {
  color: #000;
  background: #fff;
}

.product.data.items[role="tablist"] .title.active .switch:after {
  content: "";
}

.product.data.items[role="tablist"] .additional-attributes-wrapper .table-caption {
  display: none;
}

.product.data.items[role="tablist"] .additional-attributes th,
.product.data.items[role="tablist"] .additional-attributes td {
  padding: 0.25rem;
}

.widget.block-products-list,
.widget.block-new-products,
.widget.block-new-products-list,
.widget.block-new-products-images,
.widget.block-new-products-names,
.widget.block-viewed-products-grid,
.widget.block-viewed-products-list,
.widget.block-viewed-products-names,
.widget.block-viewed-products-images,
.block.upsell,
.block.crosssell,
.block.related {
  /* Commenting this aas the this stops the slides being equal heights
  and makes the actions/within the grid misalinged
  .slick-slide {
    height: auto !important;
  }*/
}

.widget.block-products-list .block-actions,
.widget.block-new-products .block-actions,
.widget.block-new-products-list .block-actions,
.widget.block-new-products-images .block-actions,
.widget.block-new-products-names .block-actions,
.widget.block-viewed-products-grid .block-actions,
.widget.block-viewed-products-list .block-actions,
.widget.block-viewed-products-names .block-actions,
.widget.block-viewed-products-images .block-actions,
.block.upsell .block-actions,
.block.crosssell .block-actions,
.block.related .block-actions {
  display: none;
}

.widget.block-products-list .product-item.is-active .product-item-info .product-item-details,
.widget.block-new-products .product-item.is-active .product-item-info .product-item-details,
.widget.block-new-products-list .product-item.is-active .product-item-info .product-item-details,
.widget.block-new-products-images .product-item.is-active .product-item-info .product-item-details,
.widget.block-new-products-names .product-item.is-active .product-item-info .product-item-details,
.widget.block-viewed-products-grid .product-item.is-active .product-item-info .product-item-details,
.widget.block-viewed-products-list .product-item.is-active .product-item-info .product-item-details,
.widget.block-viewed-products-names .product-item.is-active .product-item-info .product-item-details,
.widget.block-viewed-products-images .product-item.is-active .product-item-info .product-item-details,
.block.upsell .product-item.is-active .product-item-info .product-item-details,
.block.crosssell .product-item.is-active .product-item-info .product-item-details,
.block.related .product-item.is-active .product-item-info .product-item-details {
  transform: translateY(0);
}

.widget.block-products-list .product-item-photo > span,
.widget.block-new-products .product-item-photo > span,
.widget.block-new-products-list .product-item-photo > span,
.widget.block-new-products-images .product-item-photo > span,
.widget.block-new-products-names .product-item-photo > span,
.widget.block-viewed-products-grid .product-item-photo > span,
.widget.block-viewed-products-list .product-item-photo > span,
.widget.block-viewed-products-names .product-item-photo > span,
.widget.block-viewed-products-images .product-item-photo > span,
.block.upsell .product-item-photo > span,
.block.crosssell .product-item-photo > span,
.block.related .product-item-photo > span {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.widget.block-products-list .products-grid .product-item-info,
.widget.block-new-products .products-grid .product-item-info,
.widget.block-new-products-list .products-grid .product-item-info,
.widget.block-new-products-images .products-grid .product-item-info,
.widget.block-new-products-names .products-grid .product-item-info,
.widget.block-viewed-products-grid .products-grid .product-item-info,
.widget.block-viewed-products-list .products-grid .product-item-info,
.widget.block-viewed-products-names .products-grid .product-item-info,
.widget.block-viewed-products-images .products-grid .product-item-info,
.block.upsell .products-grid .product-item-info,
.block.crosssell .products-grid .product-item-info,
.block.related .products-grid .product-item-info {
  flex-wrap: nowrap;
}

.widget.block-products-list .products-grid .product-item-info .product-item-details,
.widget.block-new-products .products-grid .product-item-info .product-item-details,
.widget.block-new-products-list .products-grid .product-item-info .product-item-details,
.widget.block-new-products-images .products-grid .product-item-info .product-item-details,
.widget.block-new-products-names .products-grid .product-item-info .product-item-details,
.widget.block-viewed-products-grid .products-grid .product-item-info .product-item-details,
.widget.block-viewed-products-list .products-grid .product-item-info .product-item-details,
.widget.block-viewed-products-names .products-grid .product-item-info .product-item-details,
.widget.block-viewed-products-images .products-grid .product-item-info .product-item-details,
.block.upsell .products-grid .product-item-info .product-item-details,
.block.crosssell .products-grid .product-item-info .product-item-details,
.block.related .products-grid .product-item-info .product-item-details {
  flex-wrap: nowrap;
}

@media screen and (max-width: 1440px) {
  .widget.block-products-list .product-item .product-image-photo,
  .widget.block-new-products .product-item .product-image-photo,
  .widget.block-new-products-list .product-item .product-image-photo,
  .widget.block-new-products-images .product-item .product-image-photo,
  .widget.block-new-products-names .product-item .product-image-photo,
  .widget.block-viewed-products-grid .product-item .product-image-photo,
  .widget.block-viewed-products-list .product-item .product-image-photo,
  .widget.block-viewed-products-names .product-item .product-image-photo,
  .widget.block-viewed-products-images .product-item .product-image-photo,
  .block.upsell .product-item .product-image-photo,
  .block.crosssell .product-item .product-image-photo,
  .block.related .product-item .product-image-photo {
    margin: 0 auto !important;
  }
}

.block.related .field.choice.related {
  display: none;
}

.block.upsell:not(.cms-slider-block),
.block.crosssell:not(.cms-slider-block),
.block.related:not(.cms-slider-block),
.block.block-widget:not(.cms-slider-block) {
  padding-top: 0;
  padding-bottom: 3rem;
}

@media screen and (min-width: 992px) {
  .block.upsell:not(.cms-slider-block),
  .block.crosssell:not(.cms-slider-block),
  .block.related:not(.cms-slider-block),
  .block.block-widget:not(.cms-slider-block) {
    padding-top: 0;
    padding-bottom: 6rem;
  }
}

.block.upsell .slick-slider.product-items,
.block.crosssell .slick-slider.product-items,
.block.related .slick-slider.product-items,
.block.block-widget .slick-slider.product-items {
  margin-bottom: 0;
}

.block.upsell .products-grid .slick-slider .product-item,
.block.crosssell .products-grid .slick-slider .product-item,
.block.related .products-grid .slick-slider .product-item,
.block.block-widget .products-grid .slick-slider .product-item {
  padding-bottom: 0;
}

.block.upsell .slick-next,
.block.upsell .slick-prev,
.block.crosssell .slick-next,
.block.crosssell .slick-prev,
.block.related .slick-next,
.block.related .slick-prev,
.block.block-widget .slick-next,
.block.block-widget .slick-prev {
  background: transparent;
}

.block.upsell .slick-next,
.block.crosssell .slick-next,
.block.related .slick-next,
.block.block-widget .slick-next {
  right: 0;
}

@media screen and (min-width: 1440px) {
  .block.upsell .slick-next,
  .block.crosssell .slick-next,
  .block.related .slick-next,
  .block.block-widget .slick-next {
    right: calc(327.5px - 25vw);
  }
}

.block.upsell .slick-prev,
.block.crosssell .slick-prev,
.block.related .slick-prev,
.block.block-widget .slick-prev {
  left: 0;
}

@media screen and (min-width: 1440px) {
  .block.upsell .slick-prev,
  .block.crosssell .slick-prev,
  .block.related .slick-prev,
  .block.block-widget .slick-prev {
    left: calc(327.5px - 25vw);
  }
}

.block.upsell .slick-track,
.block.crosssell .slick-track,
.block.related .slick-track,
.block.block-widget .slick-track {
  will-change: transform;
  backface-visibility: hidden;
}

.block.upsell .slick-list,
.block.crosssell .slick-list,
.block.related .slick-list,
.block.block-widget .slick-list {
  margin: 0 2.75rem;
}

@media screen and (min-width: 1440px) {
  .block.upsell .slick-list,
  .block.crosssell .slick-list,
  .block.related .slick-list,
  .block.block-widget .slick-list {
    margin: 0;
  }
}

.block.upsell--light,
.block.crosssell--light,
.block.related--light,
.block.block-widget--light {
  background: transparent;
}

.block.upsell--light .slick-next:before,
.block.upsell--light .slick-prev:before,
.block.upsell--light .product-item,
.block.upsell--light .product-item a,
.block.upsell--light .block-title,
.block.crosssell--light .slick-next:before,
.block.crosssell--light .slick-prev:before,
.block.crosssell--light .product-item,
.block.crosssell--light .product-item a,
.block.crosssell--light .block-title,
.block.related--light .slick-next:before,
.block.related--light .slick-prev:before,
.block.related--light .product-item,
.block.related--light .product-item a,
.block.related--light .block-title,
.block.block-widget--light .slick-next:before,
.block.block-widget--light .slick-prev:before,
.block.block-widget--light .product-item,
.block.block-widget--light .product-item a,
.block.block-widget--light .block-title {
  color: #000;
}

.block.upsell--dark,
.block.crosssell--dark,
.block.related--dark,
.block.block-widget--dark {
  background: #343a40;
}

.block.upsell--dark .slick-next:before,
.block.upsell--dark .slick-prev:before,
.block.upsell--dark .product-item,
.block.upsell--dark .product-item a,
.block.upsell--dark .block-title,
.block.crosssell--dark .slick-next:before,
.block.crosssell--dark .slick-prev:before,
.block.crosssell--dark .product-item,
.block.crosssell--dark .product-item a,
.block.crosssell--dark .block-title,
.block.related--dark .slick-next:before,
.block.related--dark .slick-prev:before,
.block.related--dark .product-item,
.block.related--dark .product-item a,
.block.related--dark .block-title,
.block.block-widget--dark .slick-next:before,
.block.block-widget--dark .slick-prev:before,
.block.block-widget--dark .product-item,
.block.block-widget--dark .product-item a,
.block.block-widget--dark .block-title {
  color: #fff;
}

.block.upsell--dark .product-item-info,
.block.crosssell--dark .product-item-info,
.block.related--dark .product-item-info,
.block.block-widget--dark .product-item-info {
  background: transparent;
}

.block.upsell--full-width,
.block.crosssell--full-width,
.block.related--full-width,
.block.block-widget--full-width {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.block.upsell--full-width .block-content,
.block.crosssell--full-width .block-content,
.block.related--full-width .block-content,
.block.block-widget--full-width .block-content {
  max-width: 1310px;
  margin: 0 auto;
}

.block.upsell .slick-next:before,
.block.upsell .slick-prev:before,
.block.upsell .product-item,
.block.upsell .product-item a,
.block.upsell .block-title,
.block.crosssell .slick-next:before,
.block.crosssell .slick-prev:before,
.block.crosssell .product-item,
.block.crosssell .product-item a,
.block.crosssell .block-title,
.block.related .slick-next:before,
.block.related .slick-prev:before,
.block.related .product-item,
.block.related .product-item a,
.block.related .block-title {
  color: #000;
}

.admin__data-grid-outer-wrap {
  width: 100%;
}

/* override max-width for PDP page */
body.catalog-product-view .product-info-wrapper,
.catalog-product-view .product.info.detailed > .product.data {
  max-width: 1310px;
}

body.catalog-product-view .page-main,
body.page-products .page-main {
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* override the counter bg colour */
body .product[role=tablist]:not(.options) .switch .counter {
  background: #000;
}

/* fix colour of text within counter when tab is active */
body.am-tabs-view .product.data.items > .item.title.active > .switch .counter,
body.am-tabs-view .product.data.items > .item.title:not(.disabled) > .switch:active .counter {
  color: #fff;
}

/* fix button widths being zero'd in related products carousel on product pages */
body .products-grid .product-item .action.tocart,
body .products-list .product-item .action.tocart {
  padding-left: 36px;
  padding-right: 36px;
}

/* fix overflow issue for details inside details tab */
.product.info.detailed .data.item.content .am-custom-tab {
  overflow-x: auto;
}

/* unset the positioning of the add to cart button on PDP xtento hide price */
body.catalog-product-view .box-tocart.xtento-hideprice,
body.catalog-product-view .product-options-bottom .box-tocart.xtento-hideprice {
  width: 100%;
}

body.catalog-product-view .box-tocart.xtento-hideprice .actions,
body.catalog-product-view .product-options-bottom .box-tocart.xtento-hideprice .actions {
  position: relative;
}

body .xtento-hideprice ~ .product-info-price {
  display: none;
}

/* product page style amends */
body.catalog-product-view .product.media {
  width: 100%;
}

@media screen and (min-width: 992px) {
  body.catalog-product-view .product.media {
    width: 100%;
    max-width: calc(100% - 580px - 2rem);
    padding-right: 2rem;
  }
}

body.catalog-product-view .product-info-main {
  width: 100%;
}

@media screen and (min-width: 992px) {
  body.catalog-product-view .product-info-main {
    max-width: 580px;
    width: 100%;
    padding-left: 0;
  }
}

body.catalog-product-view .product-info-main .attribute.overview ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1rem;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

body.catalog-product-view .product-info-main .attribute.overview p:empty {
  display: none;
}

body.catalog-product-view .product-info-main [data-content-type="column"] {
  padding: 0;
}

/* remove social links line */
.catalog-product-view .product-social-links {
  text-align: left;
}

.catalog-product-view .product-social-links .action.mailto:after,
.catalog-product-view .product-social-links .action.tocompare:after,
.catalog-product-view .product-social-links .action.towishlist:after {
  display: none;
}

.catalog-product-view .product-social-links .action.towishlist {
  margin-top: 10px;
  margin-bottom: 20px;
}

.catalog-product-view .product-social-links .action.towishlist:before {
  line-height: 52px;
  background: #F2F2F2;
  color: #97999C;
  width: 52px;
  height: 52px;
  border-radius: 1000px;
  top: auto;
  font-size: 20px;
  vertical-align: middle;
}

.catalog-product-view .product-social-links .action.towishlist > span {
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.catalog-product-view .product-add-form .product-options-wrapper {
  border: 0;
  padding: 0;
}

.catalog-product-view .box-tocart .field.qty {
  max-width: none;
}

/* override and reset amasty tabs styles which override PDP tabs */
body.catalog-product-view.am-tabs-view .product.data.items > .item.title > .switch {
  font-size: 18px;
  margin: 0;
}

@media screen and (min-width: 992px) {
  body.catalog-product-view.am-tabs-view .product.data.items > .item.title > .switch {
    font-size: 25px;
  }
}

body.catalog-product-view.am-tabs-view .product.data.items > .item.title > .switch:after {
  top: 10px;
}

body .product-item .product-image-wrapper:before, body .product-item .product-image-wrapper:after {
  display: none;
}

body .product-item .product-image-wrapper .product-image-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* product view background colour */
.catalog-product-view .product.info.detailed {
  background: #F2F2F2;
}

/* category page pagination show dropdown padding fix */
body .limiter .limiter-options {
  padding-left: 1rem;
  padding-right: 2rem;
}

/* override add to basket and contact us font sizes on listing pages */
body.page-products .action.tocart.primary,
body.page-products .action.primary.xtento-hideprice-add_to_cart-button {
  font-size: 17px;
  padding-left: 5px;
  padding-right: 5px;
}

/* sidebar position sticky top value */
@media screen and (min-width: 992px) {
  .catalog-category-view.page-layout-2columns-left .sidebar-container,
  .catalog-category-view.page-layout-2columns-right .sidebar-container,
  .catalog-category-view.page-layout-3columns .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-left .sidebar-container,
  .catalogsearch-result-index.page-layout-2columns-right .sidebar-container,
  .catalogsearch-result-index.page-layout-3columns .sidebar-container {
    top: 210px;
  }
}

/* override the product name links */
body .product-item-link {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}

/* override inner padding for products */
body .product-item > .product-item-info .product-item-details {
  padding: 0;
}

/* remove unnneccesary bottom margin from button when logged out */
body .products-grid .product-item .action.tocart.cti-login {
  margin-bottom: 0;
}

/* remove set height from select dropdown on locator pages */
body .amlocator-search-container .amlocator-select {
  height: auto;
}

/* block related center title */
body .block.related > .block-title,
body .block.upsell > .block-title,
body .block.crosssell > .block-title {
  text-align: left;
}

/* style buttons in case of wishlist icons present or not present */
body .product-item-actions .action.towishlist {
  margin-top: 1rem;
  display: block;
  text-align: left;
}

body .product-item-actions .action.tocart.primary {
  margin-bottom: 0;
}

/* width 100% needed for the customizable options select dropdown field */
body.catalog-product-view .product-add-form .product-options-wrapper {
  width: 100%;
  margin-bottom: 0;
}

/* remove wishlist icon from related, upsell and crosssells product items and make sure the titles are not uppercase */
body .block.related .block-title.title,
body .block.upsell .block-title.title,
body .block.crosssell .block-title.title {
  text-transform: none;
  color: #000;
  letter-spacing: -0.02em;
  font-size: 22px;
}

@media screen and (min-width: 992px) {
  body .block.related .block-title.title,
  body .block.upsell .block-title.title,
  body .block.crosssell .block-title.title {
    font-size: 28px;
  }
}

body .block.related .action.towishlist,
body .block.upsell .action.towishlist,
body .block.crosssell .action.towishlist {
  display: none;
}

/* align the contact on product pages to the right */
body.catalog-product-view .product-table-container[role=table] .flex-row.center {
  text-align: right;
}

/* readmore expander */
.overview-readmore-link {
  margin-top: 1rem;
  margin-left: 0;
}

.product.attribute.overview .value {
  height: 70px;
  overflow: hidden;
  transition: height 0.6s ease-in-out;
  position: relative;
}

.product.attribute.overview .value a {
  display: inline-block;
}

.product.attribute.overview .value:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent, white);
}

.product.attribute.overview.expanded .value {
  height: auto;
  overflow: unset;
  transition: height 0.6s ease-in-out;
}

.product.attribute.overview.expanded .value:before {
  display: none;
}

.catalog-product-view .box-tocart .action.primary.tocart:focus,
.catalog-product-view .box-tocart .action.primary.tocart:hover,
.review-form-actions .action.primary:focus,
.review-form-actions .action.primary:hover {
  background-color: #7d7900;
}
