//------------------------------------//
//  StyleTile                         //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/style-tile";
//-----------------------------------//

@import "module-shared";

.catalog-product-view .box-tocart .action.primary.tocart:focus,
.catalog-product-view .box-tocart .action.primary.tocart:hover,
.review-form-actions .action.primary:focus,
.review-form-actions .action.primary:hover {
    background-color: darken((map-get($theme-colors, 'primary')), 10%);
}
