.product.attribute.overview {
    .value {
        > div {
            display: inline;
        }

        a {
            white-space: nowrap;
        }
    }
}