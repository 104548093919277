.fotorama [role="button"] {
  -webkit-appearance: none;
  border: 0 none;
  text-align: left;
}

.fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  left: 0;
}

.fotorama.fotorama--fullscreen {
  z-index: $fotorama-fullscreen-z !important;
}

.fotorama {
  .fotorama__nav--dots .fotorama__nav__frame {
    @include size(24px, 46px);
    margin-top: spacers(2);
  }
  .fotorama__dot {
    @include size(18px);
    border-radius: 1000px;
    border-color: theme-color(light);
  }

  .fotorama__active {
    .fotorama__dot {
      background-color: theme-color(light);
      border-color: theme-color(light);
    }
  }
}

.fotorama .fotorama__nav {
  padding: $fotorama-nav-padding;
}

.fotorama__nav-wrap  .fotorama__thumb-border {
  border: $fotorama-active-border;
}

.gallery-placeholder .fotorama__wrap {
  @include respond-to-up($category-layout-breakpoint) {
    @include size($fotorama-media-width, $fotorama-media-height);
  }
}

.gallery-placeholder .loading-mask {
  position: relative;
}