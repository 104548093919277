.action.sorter-action {
  @extend %u-transform-fade-transition;
  box-sizing: border-box;
  padding: 0 0 0 spacers(3);
  opacity: 1;

  @include respond-to-down(smm) {
    padding-left: spacers(1);
  }

  @include hover {
    opacity: 0.3;
  }

  &.sort-asc {
    @include ms-icon($toolbar-direction-up-icon, $toolbar-direction-icon-size);
  }

  &.sort-desc {
    @include ms-icon($toolbar-direction-down-icon, $toolbar-direction-icon-size);
  }

  span {
    @extend %u-sr-only;
  }
}